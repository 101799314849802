import { useEffect, useState } from 'react';


export const useMediaQuery = (query: string) => {
  const [matches, setMatches] = useState(false);
  useEffect(
    () => {
      const mediaQuery = window.matchMedia(query);
      setMatches(mediaQuery.matches);
      const handler = (event: any) => setMatches(event.matches);
      mediaQuery.addEventListener('change', handler);
      return () => mediaQuery.removeEventListener('change', handler);
    }, []);
  return matches;
}

export const useBreakpoints = () => {
  const breakpoints = {
    isMobile: useMediaQuery('(min-width: 320px) and (max-width: 767px)'),
    isTablet: useMediaQuery('(min-width: 768px) and (max-width: 1024px)'),
    isDesktop: useMediaQuery('(min-width: 1025px)'),
  };
  return breakpoints;
}