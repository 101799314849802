import React from 'react';
import logoImage from 'assets/images/morad-logo.png';
import './index.scss';
import { useProduct } from '../ProductContext';
import { useTranslation } from 'react-i18next';
import { useBreakpoints } from '../../hooks/mediaQueries';
import LanguageSelector from '../LanguageSelector';


const AppHeader =  () => {
  const { t } = useTranslation();
  const { isDesktop } = useBreakpoints();

  const { selectedProduct } = useProduct();

  return (
    <header>
      <a href={'https://morad.pl/'} target={'_blank'}>
        <img src={logoImage} alt={'Moard Konfigurator'} className="logo"/>
      </a>
      {isDesktop && (
        <>
          <div className="product-selector">
            {selectedProduct && t(`products.${selectedProduct?.name}`)}
          </div>
          <div className="lang-selector">
            <LanguageSelector></LanguageSelector>
          </div>
        </>
      )}
    </header>
);
}

export default AppHeader