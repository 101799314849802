import React, { useEffect, useRef, useState } from 'react';
import './index.scss';
import Configurationlayout from '../Configuration/ConfigurationLayout';
import MaterialsLayout from '../Materials/MaterialsLayout';
import { Flex, Radio, RadioChangeEvent, Space, Tabs, TabsProps } from 'antd';
import { useBreakpoints } from '../../hooks/mediaQueries';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';


export const LayoutComponent: React.FC = () => {
  const { isMobile, isDesktop } = useBreakpoints();
  const { t, i18n } = useTranslation();
  const [value, setValue] = useState<string | number>('options')
  const [visible, setVisible] = useState<boolean>(true);

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: t('product_options'),
      children: <Configurationlayout/>
    },
    {
      key: '2',
      label: t('product_colors'),
      children: <MaterialsLayout/>
    },
  ];

  const onChangeMobile = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  const toggleConfig = () => {
    setVisible(!visible);
  }

  return (
    <>
      {isDesktop && (
        <div className={`layout ${visible ? '' : 'hidden'}`}>
          <div className={'toggle-config'}
               onClick={() => toggleConfig()}>
            {visible ? <ArrowRightOutlined/> : <ArrowLeftOutlined/>}
          </div>
          <Tabs defaultActiveKey="1" items={items}/>
        </div>
      )}
      {isMobile && (
        <>
          <div className="layout-options">
            <Space style={{width: '100%' }} direction="vertical">
              <Flex vertical gap="middle">
                <Radio.Group defaultValue={'options'} buttonStyle="solid" onChange={onChangeMobile} size="large" block style={{ width: '100%' }}>
                  <Radio.Button value="options">{t('product_options')}</Radio.Button>
                  <Radio.Button value="materials">{t('product_colors')}</Radio.Button>
                </Radio.Group>
              </Flex>
            </Space>
          </div>

          <div className="layout-output">
            {value === 'options' && <Configurationlayout/>}
            {value === 'materials' && <MaterialsLayout/>}
          </div>
        </>
      )}
    </>
  );
}
