import { createContext } from "react";

interface IVarianticContext {
	environment: string;
}

export const defaultVariantic: IVarianticContext = {
	environment: "1",
};

export const VarianticContext =
	createContext<IVarianticContext>(defaultVariantic);
