import React, { useContext, useEffect, useState } from "react";
import "./Parameter.scss";
import { IParameter } from "../../../types";
import { Constant, Options, Predefined, Range } from "./types";
import { PlayerContext } from "../../../contexts/PlayerContext";

export interface IParameterProps {
	parameter: IParameter;
}

const parameterTypes: { [key: string]: React.FC<IParameterProps> } = {
	range: Range,
	predefined: Predefined,
	options: Options,
	constant: Constant,
};

const Parameter: React.FC<IParameterProps> = ({ parameter }) => {
	const [hoverDimensions, setHoverDimensions] = useState<string[]>([]);
	const ParametrComp = parameterTypes[parameter.settings.type];

	const playerContext = useContext(PlayerContext);

	useEffect(() => {
		// DIMENSION HOVER
		if (!playerContext || !playerContext.player) return;

		const player = playerContext.player;
		const paramInfo = player.selected.info.layout.parametry;
		const events = paramInfo[parameter.code].events;
		if (!events || !events.hover) return;
		if (!Array.isArray(events.hover)) return;

		setHoverDimensions(events.hover);

		events.hover.forEach((index: string) => {
			const dimension = player.dimentions[index];

			if (!dimension) return;

			dimension.visible = false;
			dimension.controlledByEvent = true;
		});
	}, [playerContext, parameter.code]);

	const toggleDimensions = (show: boolean) => {
		if (!playerContext || !playerContext.player) return;

		const player = playerContext.player;

		hoverDimensions.forEach((dimID: string) => {
			if (
				player.dimentions[dimID] &&
				player.dimentions[dimID].temp &&
				player.dimentions[dimID].temp.d.ile.r
			) {
				player.dimentions[dimID].visible = show;
				player.dimentions[dimID].hoverVisible = show;

				player.renderRequested = false;
				player.requestRenderIfNotRequested();
			}
		});
	};

	if (!ParametrComp) {
		console.error(
			"Variantic Layout",
			`Unknown ${parameter.settings.type} parameter type`
		);
		return null;
	}

	return (
		<>
			{!!hoverDimensions.length && (
				<span
					onMouseEnter={() => toggleDimensions(true)}
					onMouseLeave={() => toggleDimensions(false)}
				>
					<ParametrComp parameter={parameter} />
				</span>
			)}
			{!hoverDimensions.length && <ParametrComp parameter={parameter} />}
		</>
	);
};

export default Parameter;
