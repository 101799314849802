import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { CartProvider } from './components/CartContext';
import { ProductProvider } from './components/ProductContext';
import './i18n';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { PlayerContext } from 'contexts/PlayerContext';
import { defaultVariantic, VarianticContext } from 'contexts/VarianticContext';
import { ColorsContext, defaultColors } from 'contexts/ColorsContext';
import { defaultShapes } from './contexts/ShapesContext';



const root = ReactDOM.createRoot(document.getElementById('root')!);

const props: any = [];

const extractProps = (properties: Record<string, string>, prefix: string) => {
		return Object.keys(properties)
			.filter((key) => key.startsWith(prefix))
			.reduce((acc: any, key: string) => {
				let newKey = key.replace(prefix, "");
				newKey = newKey.charAt(0).toLowerCase() + newKey.slice(1);

				acc[newKey] = props[key];
				delete props[key];
				return acc;
			}, {});
	};

const colorsProps = extractProps(props, "color");
	const colors = {
		...defaultColors,
		...colorsProps,
	};

	const shapesProps = extractProps(props, "shape");
	const shapes = {
		...defaultShapes,
		...shapesProps,
	};

	const varianticProps = extractProps(props, "variantic");
	const variantic = {
		...defaultVariantic,
		...varianticProps,
	};

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PlayerContext.Provider
					value={{
						player: null,
						webComponent: null,
					}}
				>
        <ColorsContext.Provider value={colors}>
							<VarianticContext.Provider value={variantic}>
      <ProductProvider>
        <CartProvider>
          <App />
        </CartProvider>
      </ProductProvider>
              </VarianticContext.Provider>
        </ColorsContext.Provider>
      </PlayerContext.Provider>
    </Provider>
  </React.StrictMode>
);

