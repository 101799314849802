import React, { useContext } from "react";
import { ColorsContext } from "../../../../contexts/ColorsContext";
import { IParameterProps } from "../Parameter";

const Constant: React.FC<IParameterProps> = ({ parameter }) => {
	const colors = useContext(ColorsContext);

	return (
		<div
			className="Parameter tw-flex tw-flex-col tw-py-4 tw-border-b"
			style={{ color: colors.text, borderColor: colors.lines }}
		>
			<h3 className="tw-text-sm tw-uppercase tw-mb-2 tw-break-all">
				{parameter.name}
			</h3>
			<span className="tw-text-sm">
				<div
					className={`${
						parameter.type === "number" ? "tw-text-right" : "tw-text-left"
					} tw-break-all`}
				>
					{parameter.valueLabel} {parameter.unitLabel}
				</div>
			</span>
		</div>
	);
};

export default Constant;
