import React, { useContext, useEffect, useState } from "react";
import { ColorsContext } from "../../contexts/ColorsContext";
import { PlayerContext } from "../../contexts/PlayerContext";
import { IGroup, IMaterial, IQuery, IResponseMetadata } from "../../types";
import Material from "./Material";
import { Flex } from 'antd';
import { setGroupsMeta } from '../../redux/materialsSlice';
import { useAppDispatch } from '../../redux/hooks';

interface IQueryProps {
	group: IGroup;
	query: IQuery;
	page: number;
}

const Query: React.FC<IQueryProps> = ({ group, query, page }) => {
	const [materials, setMaterials] = useState<IMaterial[]>([]);
	const playerContext = useContext(PlayerContext);
	const colors = useContext(ColorsContext);

	const dispatch = useAppDispatch();

	useEffect(() => {
		if (!playerContext || !playerContext.player) return;

		const player = playerContext.player;
		player.setLayoutMediator
			.getMaterialList(group.filterData.id, query, page)
			.then((mats: { items: IMaterial[]; meta: IResponseMetadata }) => {
				setMaterials([...mats.items]);
				dispatch(setGroupsMeta([group.id, mats.meta]));
			});
	}, [playerContext, group.filterData.id, query, page]);

	return (
		<Flex wrap>
			{materials.map((material) => (
				<Material key={material.id} group={group} material={material} />
			))}
		</Flex>
	);
};

export default Query;
