import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { IGroup, IResponseMetadata } from '../types';
import { RootState } from "./store";
import { revertAll } from "./extra";

type TSelectedMaterials = { [key: number]: number };
type TGroupsMeta = { [key: number]: IResponseMetadata }

interface IMaterialsState {
	groups: IGroup[];
	selectedMaterials: TSelectedMaterials;
	groupsMeta: TGroupsMeta;
}

const initialState: IMaterialsState = {
	groups: [],
	selectedMaterials: {},
	groupsMeta: {},
};

export const materialsSlice = createSlice({
	name: "materials",
	initialState,
	extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
	reducers: {
		setGroups: (state, action: PayloadAction<IGroup[]>) => {
			state.groups = action.payload;
		},
		setSelectedMaterials: (
			state,
			action: PayloadAction<TSelectedMaterials>
		) => {
			state.selectedMaterials = {
				...action.payload,
			};
		},
		setGroupsMeta: (state, action: PayloadAction<[number, IResponseMetadata]>) => {
			state.groupsMeta[action.payload[0]] = action.payload[1];
		}
	},
});

export const { setGroups, setSelectedMaterials, setGroupsMeta } = materialsSlice.actions;

// selectors
export const getMaterialsSlice = (state: RootState) => state.materialsSlice;
export const getGroups = createSelector(
	getMaterialsSlice,
	(state) => state.groups
);
export const getSelectedMaterials = createSelector(
	getMaterialsSlice,
	(state) => state.selectedMaterials
);
export const getGroupsMeta = createSelector(
	getMaterialsSlice,
	(state) => state.groupsMeta
);

export default materialsSlice.reducer;
