import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { ColorsContext } from "../../contexts/ColorsContext";
import { PlayerContext } from "../../contexts/PlayerContext";
import { getSelectedMaterials } from "../../redux/materialsSlice";
import { IGroup, IMaterial } from "../../types";
import { VarianticContext } from "../../contexts/VarianticContext";

interface IMaterialProps {
	group: IGroup;
	material: IMaterial;
}

const getMaterialImageURLBase = (env: string) => {
	return`${window.variantic.environment["editor-url"]}/assets/img/import/img/small/`;
};

const Material: React.FC<IMaterialProps> = ({ group, material }) => {
	const playerContext = useContext(PlayerContext);
	const colors = useContext(ColorsContext);
	const varianticContext = useContext(VarianticContext);
	const selectedMaterial = useSelector(getSelectedMaterials);
	const selectedMaterialId = selectedMaterial[group.id];
	const isSelected = selectedMaterialId === material.id;

	const handleClick = () => {
		if (!playerContext || !playerContext.player) return;

		playerContext.player.setLayoutMediator.assignMaterial(
			group.id,
			material.id
		);
	};

	const baseURL = getMaterialImageURLBase(varianticContext.environment);

	return (
		<div
			className={'material-box'}
			onClick={handleClick}
			style={{
				borderColor: colors.lines,
			}}
		>
			<div
				className={'material-image'}
				style={{
					backgroundImage: `url("${baseURL}${material.photo}")`,
				}}
			></div>
			<div className={'material-name'}>
				{playerContext?.player.mediator.translate(material.nazwa)}
			</div>
		</div>
	);
};

export default Material;
