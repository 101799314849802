import { Avatar, Badge } from 'antd';
import { ArrowLeftOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../CartContext';
import './index.scss';

interface ITopBarProps {
	backButton?: () => void;
}

export const TopBar: React.FC<ITopBarProps> = ({backButton}) => {
  const navigate = useNavigate();
  const { cartItemCount, formattedCartTotals } = useCart();

  const goToCart = () => {
    navigate("/cart");
  }

  return (
    <div className={'top-bar-content'}>

      {backButton && (
        <div className="go-back"
             onClick={() => backButton()}>
          <ArrowLeftOutlined/>
        </div>
      )}

      {cartItemCount() > 0 && (
        <>

          <div className={'price-mobile cart-total'}>
            {formattedCartTotals()}
          </div>
          <div className="add-to-cart-mobile"
               onClick={goToCart}>
            <Badge count={cartItemCount()}>
              <Avatar
                style={{backgroundColor: 'transparent'}}
                size="large"
                shape="square" icon={<ShoppingCartOutlined/>}/>
            </Badge>
          </div>
        </>
      )}

    </div>

  )
}
