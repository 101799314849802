import { createContext } from "react";

interface IPlayerContext {
	player: any;
	webComponent: any;
}

export const PlayerContext = createContext<IPlayerContext>({
	player: null,
	webComponent: null,
});
