import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { ICategory, ICategoryWithParameters, IParameter } from "../types";
import { RootState } from "./store";
import { revertAll } from "./extra";

interface IConfigurationState {
	parameters: IParameter[];
	categories: ICategory[];
	price: string;
	unit: string;
}

const initialState: IConfigurationState = {
	parameters: [],
	categories: [],
	price: "",
	unit: "",
};

export const configurationSlice = createSlice({
	name: "configuration",
	initialState,
	extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
	reducers: {
		setParameters: (state, action: PayloadAction<IParameter[]>) => {
			state.parameters = action.payload;
		},
		setCategories: (state, action: PayloadAction<ICategory[]>) => {
			state.categories = action.payload;
		},
		setPrice: (state, action: PayloadAction<string>) => {
			state.price = action.payload;
		},
		setUnit: (state, action: PayloadAction<string>) => {
			state.unit = action.payload;
		},
	},
});

export const { setParameters, setCategories, setPrice, setUnit } =
	configurationSlice.actions;

// selectors
export const getConfiguration = (state: RootState) => state.configurationSlice;
export const getCategories = createSelector(
	getConfiguration,
	(state) => state.categories
);
export const getVisibleCategories = createSelector(
	getCategories,
	(categories) => categories.filter((c) => c.visible)
);
export const getParameters = createSelector(
	getConfiguration,
	(state) => state.parameters
);
export const getVisibleCategoriesWithParameters = createSelector(
	getConfiguration,
	({ parameters, categories }): ICategoryWithParameters[] => {
		return categories
			.filter((c) => c.visible)
			.map((category) => {
				const categoryParameters = category.params
					.map((paramCode) =>
						parameters.find((param) => param.code === paramCode)
					)
					.filter((param) => !!param) as IParameter[];

				return {
					...category,
					parameters: categoryParameters,
				};
			});
	}
);
export const getPrice = createSelector(
	getConfiguration,
	(state) => state.price
);
export const getUnit = createSelector(getConfiguration, (state) => state.unit);

export default configurationSlice.reducer;
