import React, { useContext, useMemo } from 'react';
import { ColorsContext } from "../../../../contexts/ColorsContext";
import { PlayerContext } from "../../../../contexts/PlayerContext";
import { ShapesContext } from "../../../../contexts/ShapesContext";
import { IParameterProps } from "../Parameter";
import { Flex, Radio, RadioChangeEvent, Select, Space } from 'antd';

interface IOptionsProps extends IParameterProps {
	onClick: (value: string) => void;
	visibilities: Record<number | string, boolean>;
}

const ListOptions: React.FC<IOptionsProps> = ({
	visibilities,
	parameter,
	onClick,
}) => {

	const onChange = (e: RadioChangeEvent) => {
		const value = e.target.value;
		onClick(value);
	};
	const colors = useContext(ColorsContext);
	const shapes = useContext(ShapesContext);


	const options = useMemo(() => {
		const _options: any[] = [];
		Object.keys(parameter.settings.options).map((key, i) => {
			const value = key;
			if (!visibilities[value]) return null;
			const label = parameter.settings.options[key];

			_options.push({
				value, label,
			});
		});
		return _options;
	}, [parameter.settings.options]);


	return (
		<Space style={{ width: '100%' }} direction="vertical">
			 <Select
				defaultValue={parameter.settings.options[parameter.value]}
				style={{ width: '100%' }}
				onChange={onClick}
				options={options}
			/>
		</Space>
	);
};

const ButtonOptions: React.FC<IOptionsProps> = ({
	visibilities,
	parameter,
	onClick,
}) => {

	const onChange = (e: RadioChangeEvent) => {
		const value = e.target.value;
		onClick(value);
	};

	return (
		<Flex vertical gap="middle">
      <Radio.Group
				block
				value={parameter.value}
				style={{ width: '100%' }}
				onChange={onChange}>
				{Object.keys(parameter.settings.options).map((key, i) => {
					const value = Number(key);
		 			if (!visibilities[value]) return null;
		 			const label = parameter.settings.options[key];
					return (
						<Radio.Button value={value}>{label}</Radio.Button>
					)
				})}
      </Radio.Group>
		</Flex>
	)
};

const Options: React.FC<IParameterProps> = ({ parameter }) => {
	const OptionsComp =
		parameter.settings.listType === "1" ? ButtonOptions : ListOptions;
	const playerContext = useContext(PlayerContext);
	const colors = useContext(ColorsContext);

	const visibilities =
		playerContext.player.mediator.getListOptionsVisibilities(parameter);

	const onClick = (value: string) => {
		if (!playerContext) return;

		const player = playerContext.player;
		if (!player) return;

		player.setSelected(player.setRoots.filterScenes()[0]);
		player.mediator.changeParameter(parameter.code, value);
	};

	return (
		<Space style={{ width: '100%' }} direction="vertical">
			<h3>
				{parameter.name}
			</h3>
			<OptionsComp
				visibilities={visibilities}
				parameter={parameter}
				onClick={onClick}
			/>
		</Space>
	);
};

export default Options;
