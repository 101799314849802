import React, { useContext, useEffect, useRef, useState } from 'react';
import { useCart } from '../CartContext';
import { CartItem } from '../../db';
import { Avatar, Badge, ConfigProvider, FloatButton } from 'antd';
import { ArrowLeftOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useProduct } from '../ProductContext';
import Loading from '../Loading';
import './index.scss';
import { useTranslation } from 'react-i18next';
import { LayoutComponent } from '../Layout';
import { useAppDispatch } from '../../redux/hooks';
import { PlayerContext } from '../../contexts/PlayerContext';
import { useSelector } from 'react-redux';
import { getUnit, setCategories, setParameters, setUnit } from '../../redux/configurationSlice';
import { setGroups, setSelectedMaterials } from '../../redux/materialsSlice';
import { setPlayerLoaded } from '../../redux/modesSlice';
import { useBreakpoints } from '../../hooks/mediaQueries';
import { TopBar } from '../topBar';


export const PlayerComponent: React.FC = () => {
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [player, setPlayer] = useState<any>();
  const playerRef = useRef<any>();
  const [price, setPrice] = useState<number>(0);
  const [products, setProducts] = useState<number>(0);
  const [doc, setDoc] = useState<any>();
  const { items, addItem, deleteItem, cartItemCount, formattedCartTotals, formatAmount, replaceItem } = useCart();
  const { isDesktop, isMobile, isTablet } = useBreakpoints();
  const { selectedProduct, selectedProductStorage } = useProduct();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState<string>(i18n.language);
  const [configurationId, setConfigurationId] = useState<string | null>(null);
  const [ready, setReady] = useState<boolean>(false);
  let { configId } = useParams();

  const dispatch = useAppDispatch();
	const playerContext = useContext(PlayerContext);
	const selectedUnit = useSelector(getUnit);

  const updatePlayer = async () => {
		const playerDOM = playerRef.current;
		if (!playerDOM || !playerContext) return;

		const player = playerDOM.player;
		playerContext.player = player;
		playerContext.webComponent = playerRef.current;

    if (player) {
      player.setSelected(player.setRoots.filterScenes()[0]);

      const {categories, params} = player.mediator.getConfigurationData();

      dispatch(setParameters(params));
      dispatch(setCategories(categories));
      dispatch(setUnit(player.targetUnit));

      const groupData = await player.setLayoutMediator.getGroupData();
      dispatch(setGroups(groupData));

      const selectedMaterials = player.setGroupsMaterial;
      dispatch(setSelectedMaterials(selectedMaterials));
    }
	};

  useEffect(() => {
    if (!selectedProductStorage) {
      navigate('/');
    }

    if (configId) {
      setConfigurationId(configId);
    }
  }, []);

  useEffect(() => {
		if (!selectedUnit) return;

		updatePlayer();
		// eslint-disable-next-line
	}, [selectedUnit]);

  useEffect(() => {
		if (!playerRef || !playerRef.current) return;
		const playerDOM = playerRef.current;

		const initEvents = () => {
			if (!playerDOM) return;

			dispatch(setPlayerLoaded(true));
			updatePlayer();
			//updatePrice();
			//playerDOM.addEventListener("updatePrice", updatePrice);
			playerDOM.addEventListener("playerReloaded", () => { updatePlayer(); });
			playerDOM.addEventListener("playerSceneReloaded", () => {  updatePlayer(); });
      setReady(true);
		};

		playerDOM.addEventListener("playerLoaded", initEvents);

		return () => {
			playerDOM.removeEventListener("playerLoaded", initEvents);
			//playerDOM.addEventListener("updatePrice", updatePrice);
			playerDOM.removeEventListener("playerReloaded", updatePlayer);
			playerDOM.removeEventListener("playerSceneReloaded", updatePlayer);
		};
		// eslint-disable-next-line
	}, [playerRef.current, dispatch]);

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.addEventListener('playerLoaded', () => {
        setPlayer(playerRef.current.player);
        //playerRef.current.player.setLayoutMediator.changeView('edit');
      });
    }
  }, [playerRef.current]);

  useEffect(() => {
    if (selectedProduct) {
      console.log(selectedProduct);
    }
  }, [selectedProduct])


  useEffect(() => {
    if (player && selectedProductStorage) {
      setPlayer(playerRef.current.player);
      player.eventsDOM.addEventListener('updatePrice', () => {
        setPrice(player.mediator.getPrice());
      });
      setPrice(player.mediator.getPrice());
    }
  }, [player]);

  const addToCart = async (oldConfigId: string | undefined) => {
    setIsLoading(true);

    if (oldConfigId) {
      await replaceItem(oldConfigId);
    }

    setTimeout(async () => {
      const image = await takeSnapshot();
      const configId = await saveConfiguration();
      const doc = await generateDoc();

      const item: CartItem = {
        name: selectedProduct.product,
        type: selectedProduct.componentId,
        price,
        image,
        doc,
        configId,
      }

      await addItem(item);
      setIsLoading(false);
    });
  };

  const goBack = () => {
    navigate('/');
  }

  const takeSnapshot = async () => {
    return await playerRef.current.takeASnapshot();
  }

  const saveConfiguration = async () => {
    try {
      const config = await playerRef.current.saveConfiguration();
      if (config) {
        const _config = JSON.parse(config);
        return _config.id;
      } else {
        return null;
      }
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  const generateDoc = async () => {
    let doc = null;
    try {
      if (!player.selected) {
        player.setSelected(player.setRoots[0]);
      }
      doc = await player.mediator.generateDocumentation(false);
    } catch (error) {
      console.log(error);
    }
    return doc;
  }

  const goToCart = () => {
    navigate("/cart");
  }

  return (
    <>
      {isMobile && (
        <div className="top-bar">
          <TopBar backButton={goBack}></TopBar>
        </div>
      )}

      {selectedProduct && (
        isMobile ? (
          <div className={'player-wrapper-mobile'}>
            <variantic-player
              ref={playerRef}
              component-id={selectedProduct.componentId}
              documentation-title=""
              configuration-id={configurationId}
              disable-layout={true}
              lang-code={lang}
              ar-enabled="1"
              width="100%"
              height="100%"
            />
          </div>
        ) : (
          <variantic-player
            ref={playerRef}
            component-id={selectedProduct.componentId}
            documentation-title=""
            configuration-id={configurationId}
            disable-layout={true}
            lang-code={lang}
            ar-enabled="1"
            width="100%"
            height="100%"
          />
        )
      )}

      {ready && <LayoutComponent/>}

      <FloatButton description={configId ? t('replace_in_cart') : t('add_to_cart')}
                   type="primary"
                   className="add-to-cart-btn"
                   badge={{ count: isDesktop ? cartItemCount() : null }}
                   onClick={() => addToCart(configId)}/>

      <div className="price">
        <span>{t('current_product')}</span>
        {formatAmount(price)}
      </div>

      {(isDesktop || isTablet) && (
        <>
          <div className="price cart-total">
            <span>{t('your_shopping_cart')}</span>
            {formattedCartTotals()}
          </div>
          <div className="add-to-cart"
               onClick={goToCart}>
            <Badge count={cartItemCount()}>
              <Avatar
                style={{backgroundColor: 'transparent'}}
                size="large"
                shape="square" icon={<ShoppingCartOutlined/>}/>
            </Badge>
          </div>
          <div className="go-back"
               onClick={goBack}>
            <ArrowLeftOutlined/>
          </div>
        </>
      )}
      <Loading isLoading={isLoading}
               style={{'z-index': 1001}}>
      </Loading>
    </>
  );
}