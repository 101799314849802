import './App.scss';
import React, { useEffect, useState } from 'react'
import AppHeader from './components/appHeader';
import { ConfigProvider } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import { AppRoutes } from './components/AppRoutes';
import { useTranslation } from 'react-i18next';
import { useBreakpoints } from './hooks/mediaQueries';
import PreventPullToRefresh from './providers/preventPullToRefresh';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "variantic-player": {
        "component-id": string;
        ref: any;
        width: string;
        height: string;
      };
    }
  }
}


function App() {
  const { isMobile, isDesktop } = useBreakpoints();
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState<string>(i18n.language);

  return (
    <>
      <PreventPullToRefresh>
        <BrowserRouter basename={'/mob'}>
        {/*<BrowserRouter>*/}
          <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#fec526',
              borderRadius: 2,
              colorBgContainer: '#ffffff',
              fontFamily: "Montserrat",
            },
          }}
        >
            <div className="App">
              <div className="header">
                <AppHeader></AppHeader>
              </div>
              <div className="container">
                <AppRoutes></AppRoutes>
              </div>
              {isDesktop && (
              <div className="terms"><a href={'https://morad.pl/' + i18n.language + '/ogolne-warunki-sprzedazy-i-gwarancji'} target="_blank">{t('terms')}</a></div>
              )}
            </div>
          </ConfigProvider>
        </BrowserRouter>
      </PreventPullToRefresh>
    </>
  );
}

export default App;
