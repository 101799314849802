import React, { useContext } from "react";
import { ColorsContext } from "../../../../contexts/ColorsContext";
import { PlayerContext } from "../../../../contexts/PlayerContext";
import { ShapesContext } from "../../../../contexts/ShapesContext";
import { IParameterProps } from "../Parameter";
import { Button } from 'antd';

interface IPredefinedButton {
	value: string;
	label: string;
	selected: boolean;
	onClick: (val: string) => void;
}

export const PredefinedButton: React.FC<IPredefinedButton> = ({
	value,
	label,
	selected,
	onClick,
}) => {
	const colors = useContext(ColorsContext);
	const shapes = useContext(ShapesContext);

	return (
		<Button onClick={() => onClick(value)}
						style={{ width: '56px' }}
						key={`${label}-${value}`}
						type={selected ? 'primary' : 'default'}
						>
			{label}
		</Button>
	);
};

const Predefined: React.FC<IParameterProps> = ({ parameter }) => {
	const playerContext = useContext(PlayerContext);
	const colors = useContext(ColorsContext);

	const onClick = (value: string) => {
		if (!playerContext) return;

		const player = playerContext.player;
		if (!player) return;

		player.setSelected(player.setRoots.filterScenes()[0]);
		player.mediator.changeParameter(parameter.code, value);
	};

	const visibilites =
		playerContext?.player.mediator.getNumberPredefinedOptionsVisibilities(
			parameter
		);

	return (
		<>
			<div className="tw-flex tw-text-sm tw-justify-between tw-mb-2">
				<h3>{parameter.name}</h3>
			</div>
			<span className="tw-text-sm">
				<div className="tw-flex tw-flex-wrap tw-gap-3">
					{Object.keys(parameter.settings.options).map((key, i) => {
						const value = parameter.settings.options[key];
						if (!visibilites[value]) return null;

						const label = parameter.settings.optionsLabels[key];
						const selected = parameter.value === value;

						return (
							<PredefinedButton
								key={`${parameter.code}-${value}-${i}`}
								label={label}
								value={value}
								selected={selected}
								onClick={onClick}
							/>
						);
					})}
				</div>
			</span>
		</>
	);
};

export default Predefined;
