import { ICategoryWithParameters } from "../../types";
import Parameter from "./parameter/Parameter";
import React from 'react';

interface ICategoryProps {
	category: ICategoryWithParameters;
}

const Category: React.FC<ICategoryProps> = ({ category }) => {
	return (
		<>
			{category.parameters
				.filter((p) => p.visible)
				.map((p) => (
					<Parameter key={p.code} parameter={p} />
				))}
		</>
	);
};

export default Category;
