import { useSelector } from "react-redux";
import { getGroups, getGroupsMeta } from '../../redux/materialsSlice';
import Group from "./Group";
import React, { useMemo, useState } from 'react';
import { CollapseProps, Collapse, Pagination, PaginationProps } from 'antd';
import { IGroup } from '../../types';
import { CapsuleTabs } from 'antd-mobile';
import { DownOutlined } from '@ant-design/icons';
import { useBreakpoints } from '../../hooks/mediaQueries';

const MaterialsLayout = () => {
	const groups = useSelector(getGroups);
	const groupsMeta = useSelector(getGroupsMeta);
	const { isMobile, isDesktop } = useBreakpoints();
	const [value, setValue] = useState<string | null>(null);
	const [currentPage, setCurrentPage] = useState(1);

 const onChange: PaginationProps['onChange'] = (page) => {
    setCurrentPage(page);
  };

	const items: CollapseProps['items'] = useMemo(() => {
		const _items = groups.map((group: IGroup, idx: number) =>
			({
				key: `${idx}`,
				label: <h2 className={'no-line'}>{group.name}</h2>,
				children: <>
					{groupsMeta[group.id] && (
						<Pagination
							simple={{ readOnly: true }}
							defaultCurrent={1}
							total={groupsMeta[group.id]?.totalItems}
							defaultPageSize={30}
							showSizeChanger={false}
							onChange={onChange}
							align="center"
							style={{marginBottom: '20px'}}
						/>
					)}
					<Group key={group.id} group={group} page={currentPage}/>
				</>,
			}));
		return _items;
	}, [groups, groupsMeta, currentPage]);

	const handleTabClick = (event: any) => {
    const key = event.target.parentNode.dataset.tab;
    if (key) {
      if (key === value) {
        // Deselect the currently active tab
        setValue(null);
      } else {
        // Select a new tab
        setValue(key);
      }
    }
  };

	return (
		<>
			{isDesktop && (
				<>
					{!groups.length && "Nothing to show."}
					<Collapse items={items} ghost defaultActiveKey={['0']} />
				</>
			)}

			{isMobile && (
				<div onClick={handleTabClick}>
					<CapsuleTabs className={'materials-selector'} activeKey={value}>
						{groups.map((group, i) => (
							<CapsuleTabs.Tab title={group.name} key={`${group.id}-${i}`} data-tab={`${group.id}-${i}`}>
								<div className="handle" onClick={() => setValue(null)}>
									<DownOutlined/>
								</div>
								<Pagination
									simple={{ readOnly: true }}
									defaultCurrent={1}
									total={groupsMeta[group.id]?.totalItems}
									defaultPageSize={30}
									showSizeChanger={false}
									onChange={onChange}
									align="center"
									style={{marginBottom: '20px', marginTop: '20px'}}
								/>
								<div className={'scrollable-area'}>
									<Group key={group.id} group={group} page={currentPage}/>
								</div>
							</CapsuleTabs.Tab>
							))}
					</CapsuleTabs>
				</div>
			)}
		</>
	);
};

export default MaterialsLayout;
