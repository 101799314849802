import React, { useContext, useEffect, useState } from 'react';
import { IGroup, IQuery } from '../../types';
import Query from "./Query";
import { ColorsContext } from "../../contexts/ColorsContext";
import { Collapse } from 'antd';

interface IGroupProps {
	group: IGroup;
	page: number;
}

const Group: React.FC<IGroupProps> = ({ group, page }) => {
	const [open, setOpen] = useState(false);
	const colors = useContext(ColorsContext);
	const [queries, setQueries] = useState<any>(group.filterData.queries);

	return (
		<>
				{queries.length > 1
					? queries
							.filter((query: any) => query.name !== "ALL")
							.map((query: any) => (
								<Query
									key={`${group.name}-${query.name}`}
									group={group}
									query={query}
									page={page}
								/>
							))
					: queries.map((query: any) => (
							<Query
								key={`${group.name}-${query.name}`}
								group={group}
								query={query}
								page={page}
							/>
					  ))}
			</>
	);
};

export default Group;
