import { useSelector } from "react-redux";
import {
	getUnit,
	getVisibleCategoriesWithParameters,
} from "../../redux/configurationSlice";
import Category from "./Category";
import React, { useEffect, useState } from 'react';
import { useBreakpoints } from '../../hooks/mediaQueries';
import { CapsuleTabs } from 'antd-mobile';
import { DownOutlined } from '@ant-design/icons';
import { useRef } from 'react';

const Configurationlayout = () => {
	const categories = useSelector(getVisibleCategoriesWithParameters);
	const selectedUnit = useSelector(getUnit);
	const { isMobile, isDesktop } = useBreakpoints();
	const [value, setValue] = useState<string | null>(null);

	const scrollContainerRef = useRef<HTMLDivElement | null>(null);

	const clickTab = (val: any) => {
		setValue(val === value ? null : val)
	}

	const handleTabClick = (event: any) => {
    const key = event.target.parentNode.dataset.tab;
    if (key) {
      if (key === value) {
        // Deselect the currently active tab
        setValue(null);
      } else {
        // Select a new tab
        setValue(key);
      }
    }
  };

	useEffect(() => {

		let startY: number | null = null;

    const preventPullToRefresh = (e: TouchEvent) => {
      const scrollContainer = scrollContainerRef.current;

			if (scrollContainer && scrollContainer.scrollTop === 0 && startY && (e.touches[0].clientY > startY)) {
				if (e.cancelable) {
					e.preventDefault(); // Prevent pull-to-refresh when at the top and swiping down
				}
      }
    };

    const container = scrollContainerRef.current;


		if (container) {
			// Attach listeners if the container exists
			container.addEventListener('touchstart', (e: TouchEvent) => {
				// Optional: Track starting touch position if needed
				startY = e.touches[0].clientY;
			});


			container.addEventListener('touchmove', preventPullToRefresh, {passive: false});
		}

    return () => {
			container && container.removeEventListener('touchmove', preventPullToRefresh);
    };
  }, [value]);

	return (
		<>
			{isDesktop && (
				categories.map((c, i) => (
					<>
						<h2>{c.name}</h2>
						<Category key={`${selectedUnit}-${c.id}-${i}`} category={c} />
					</>
				))
			)}

			{isMobile && (
				<div onClick={handleTabClick}>
					<CapsuleTabs activeKey={value} >
						{categories.map((c, i) => (
							<CapsuleTabs.Tab title={c.name} key={`section_${selectedUnit}-${c.id}-${i}`} data-tab={`section_${selectedUnit}-${c.id}-${i}`}>
								 <div className="handle" onClick={() => clickTab(null)}>
									 <DownOutlined />
								 </div>
								 <div className={'scrollable-area'}
											ref={scrollContainerRef}>
									<Category key={`${selectedUnit}-${c.id}-${i}`} category={c} />
								 </div>
							 </CapsuleTabs.Tab>
						))}
					</CapsuleTabs>
				</div>
			)}
		</>
	);
};

export default Configurationlayout;
