import React, { useContext, useEffect, useState } from "react";
import { PlayerContext } from "../../../../contexts/PlayerContext";
import { IParameterProps } from "../Parameter";
import { DebounceInput } from "react-debounce-input";
import { convertUnit } from "../../../../utils/converter";
import { ColorsContext } from "../../../../contexts/ColorsContext";
import { ShapesContext } from "../../../../contexts/ShapesContext";
import { Col, InputNumber, InputNumberProps, Row, Slider, Space } from 'antd';

const Range: React.FC<IParameterProps> = ({ parameter }) => {
	const playerContext = useContext(PlayerContext);
	const colors = useContext(ColorsContext);
	const shapes = useContext(ShapesContext);
	const [paramValue, setParamValue] = useState<string>(parameter.valueLabel);

	useEffect(() => {
		setParamValue(parameter.valueLabel);
	}, [parameter.valueLabel]);

	// const onRangeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
	// 	if (!e.target || !playerContext) return;
	//
	// 	const player = playerContext.player;
	// 	if (!player) return;
	//
	// 	player.setSelected(player.setRoots.filterScenes()[0]);
	// 	player.mediator.changeParameter(parameter.code, e.target.value);
	// };

	const onRangeChange: InputNumberProps['onChange'] = (newValue) => {
		if (!newValue || !playerContext) return;

		const player = playerContext.player;
		if (!player) return;

		const convertedUnitValue =
			player.targetUnit !== "mm"
				? player.mediator.convert(newValue, "mm", player.targetUnit)
				: newValue;
		setParamValue(convertedUnitValue);
		player.setSelected(player.setRoots.filterScenes()[0]);
		player.mediator.changeParameter(parameter.code, newValue);
	};

	const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!e.target || !playerContext) return;

		const player = playerContext.player;
		if (!player) return;

		const valueInput = e.target.value;

		const { value, inputValue } = convertUnit(
			player,
			valueInput,
			parameter.unit,
			parameter.settings.range.min,
			parameter.settings.range.max
		);

		e.target.value = inputValue;

		player.setSelected(player.setRoots.filterScenes()[0]);
		player.mediator.changeParameter(parameter.code, value);
	};


	return (
		<Space style={{ width: '100%' }} direction="vertical">
		<h3>{parameter.name}</h3>
		<Row>
      <Col span={18}>
        <Slider
          min={parameter.settings.range.min}
          max={parameter.settings.range.max}
					style={{ marginRight: '16px' }}
          onChange={onRangeChange}
          value={Number(paramValue)}
        />
      </Col>
      <Col span={6}>
        <InputNumber
          min={parameter.settings.range.min}
          max={parameter.settings.range.max}
					style={{ width: '100%' }}
          value={paramValue}
          onChange={onRangeChange}
        />
      </Col>
    </Row>
		</Space>
	)

};

export default Range;
